/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useState } from 'react'
import { Button, Divider, Form, Input, Modal } from 'antd'
import { validateMessages } from '../model/enum/message'
import '../assets/scss/components/_modal.scss'
import Title from 'antd/es/typography/Title'
import { useAuth } from '../contexts/AuthContext'
import { LoginRequest } from '../model/interface/response/auth'
import liff from '@line/liff'

interface ModalLoginProps {
    modal_label: string
    where_modal: string
}

const ModalLogin: React.FC<ModalLoginProps> = ({
    modal_label,
    where_modal,
}) => {
    const [open, setOpen] = useState(false)
    const [form] = Form.useForm()
    const { isLoggedIn, login } = useAuth()

    React.useEffect(() => {
        liff.init({ liffId: process.env.REACT_APP_LINE_LIFF_ID || '' })
    }, [])

    const showModal = () => {
        setOpen(true)
    }

    const handleCancel = () => {
        setOpen(false)
        form.resetFields()
    }

    const loginWithLine = () => {
        try {
            liff.login()
        } catch (error) {
            console.log('🚀 ~ loginWithLine ~ error:', error)
        }
    }

    const onFinish = (values: LoginRequest) => {
        login(values)
        if (isLoggedIn) {
            handleCancel()
        }
    }

    return (
        <>
            {where_modal == 'footer' && (
                <a onClick={showModal}>{modal_label}</a>
            )}
            {where_modal == 'navbar' && (
                <a onClick={showModal}>{modal_label}</a>
            )}
            <Modal
                open={open}
                onCancel={handleCancel}
                footer={null}
                className="modal_card"
                centered
            >
                <div className="head_text">
                    <Title>ยินดีต้อนรับกลับมาอีกครั้ง</Title>
                    <div>
                        เข้าสู่ระบบด้วยข้อมูลที่คุณใส่ระหว่างการลงทะเบียนของคุณ
                    </div>
                </div>

                <Form
                    form={form}
                    onFinish={onFinish}
                    requiredMark={false}
                    layout={'vertical'}
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        name={['email']}
                        label="อีเมล"
                        rules={[
                            {
                                required: true,
                                message: validateMessages.required_mail,
                            },
                            {
                                pattern:
                                    /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                message: validateMessages.types.email,
                            },
                        ]}
                    >
                        <Input placeholder="ตัวอย่าง example@email.com" />
                    </Form.Item>

                    <Form.Item
                        name={['password']}
                        label="รหัสผ่าน"
                        rules={[
                            {
                                required: true,
                                message: validateMessages.required_pw,
                            },
                        ]}
                    >
                        <Input.Password placeholder="กรุณากรอกรหัสผ่าน" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            size="large"
                            style={{ width: '100%', marginTop: '12px' }}
                        >
                            เริ่มต้นใช้งาน!
                        </Button>
                    </Form.Item>
                    <Divider style={{ margin: '0px' }}>หรือ</Divider>
                </Form>

                <Button
                    htmlType="submit"
                    shape="round"
                    size="large"
                    style={{ width: '100%' }}
                    onClick={loginWithLine}
                >
                    เข้าสู่ระบบด้วย Line
                </Button>

                <span>
                    <span>ยังไม่มีบัญชีผู้ใช้? </span>
                    <a href="/create">สมัครสมาชิก</a>
                </span>
            </Modal>
        </>
    )
}

export default ModalLogin
