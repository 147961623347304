import { prefixApi } from './prefix.api'
import { axiosInstance, configHeader } from './axios.api'
import axios, { AxiosError } from 'axios'
import { PostVerifyQR, ResponsePayment } from '../model/interface/response/payment'

export class PaymentService {
    private static paymentUrl = `${prefixApi.payment}`

    public static async postPaymentVerify(body: PostVerifyQR): Promise<ResponsePayment> {
        try {
            const url = `${this.paymentUrl}`
            const response = await axiosInstance.post(url, body, configHeader())
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError
                if (axiosError.response) {
                    throw axiosError.response.data
                } else if (axiosError.request) {
                    throw axiosError.request
                } else {
                    throw axiosError.message
                }
            } else {
                throw error
            }
        }
    }
}
