import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Radio, Space, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { ChoiceProductData } from '../../../../model/interface/response/products'
import { useNavigate } from 'react-router-dom'
import './perferredOptionPage.scss'
import { ProductService } from '../../../../services/product.service'
import Title from 'antd/es/typography/Title'

interface ChoiceProps {
    current: number
    onNext: () => void
    onPrev: () => void
    data: ChoiceProductData | undefined
    numberState: number
}

const ChoicePage = (props: ChoiceProps): JSX.Element => {
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(location.search)
    const productId = urlParams.get('productId')
    const refId = urlParams.get('ref')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalContent, setModalContent] = useState({ title: '', content: '' })
    const [productDetail, setProductDetail] = useState<ChoiceProductData>()
    const [selectedOptions, setSelectedOptions] = useState<{
        [key: number]: number
    }>({})

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.data) {
                    setProductDetail(props.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [props.data])

    const handleRadioChange = (productIndex: number, choiceIndex: number) => {
        setSelectedOptions((prevOptions) => ({
            ...prevOptions,
            [productIndex]: choiceIndex,
        }))
    }

    const downloadPDF = async () => {
        try {
            const filesToMerge = Object.keys(selectedOptions).map(
                (productIndex) => {
                    const choiceIndex = selectedOptions[parseInt(productIndex)]
                    const product =
                        productDetail?.productItem[parseInt(productIndex)]
                    return product?.choiceItem[choiceIndex]?.file ?? ''
                }
            )
            if (productId && refId) {
                await ProductService.mergeFile({
                    selected: true,
                    downloaded: false,
                    productId: productId,
                    refId: refId,
                    fileArr: filesToMerge,
                })
                props.onNext()
            }
        } catch (error) {
            showModal(
                'ดาวน์โหลดไฟล์ล้มเหลว',
                'กรุณาติดต่อแอดมิน เพื่อดาวน์โหลดผลิตภัณฑ์'
            )
        }
    }
    const showModal = (title: string, content: string) => {
        setModalContent({ title, content })
        setIsModalVisible(true)
    }

    const handleOk = () => {
        navigate(`/history`)
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    
    return (
        <>
            <div className="perferred-option-container">
                <div className="head_content" onClick={() => navigate(-1)}>
                    <LeftOutlined />
                    <Typography.Paragraph style={{ margin: 0 }}>
                        ย้อนกลับ
                    </Typography.Paragraph>
                </div>

                <Form
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                    }}
                    id="choice_form"
                    onFinish={downloadPDF}
                    onFinishFailed={() =>
                        console.error(
                            'Please select an option for each product.'
                        )
                    }
                >
                    <div className="radio-button-container">
                        {productDetail?.productItem.map(
                            (product, productIndex) => (
                                <div key={productIndex}>
                                    <Title level={2}>{product.title}</Title>
                                    <div className="group-flex-start">
                                        <div
                                            className="text-descriptions"
                                            dangerouslySetInnerHTML={{
                                                __html: product.description,
                                            }}
                                        ></div>
                                        <Form.Item
                                            className="custom-form-item"
                                            name={`product_${productIndex}`}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'กรุณาเลือกไฟล์ PDF',
                                                },
                                            ]}
                                        >
                                            <Radio.Group
                                                onChange={(e) =>
                                                    handleRadioChange(
                                                        productIndex,
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                            >
                                                <Space direction="vertical">
                                                    {product.choiceItem.map(
                                                        (
                                                            choice,
                                                            choiceIndex
                                                        ) => (
                                                            <Radio
                                                                key={
                                                                    choiceIndex
                                                                }
                                                                value={
                                                                    choiceIndex
                                                                }
                                                            >
                                                                {choice.title}
                                                            </Radio>
                                                        )
                                                    )}
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </Form>
                <div className="button">
                    <Button
                        form="choice_form"
                        shape="round"
                        type="primary"
                        htmlType="submit"
                    >
                        ดาวน์โหลดไฟล์ PDF
                    </Button>
                </div>
            </div>

            <Modal
                title={modalContent.title}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        ยกเลิก
                    </Button>,
                    <Button key="ok" type="primary" onClick={handleOk}>
                        ตกลง
                    </Button>,
                ]}
            >
                <p>{modalContent.content}</p>
            </Modal>
        </>
    )
}

export default ChoicePage
