import { Spin, Result } from 'antd'
import React from 'react'

const LoadingBox = (): React.ReactElement => {
    return (
        <Result
            icon={<Spin size="large" data-testid="spin-loading" />}
            title="กำลังโหลด"
        />
    )
}

export default LoadingBox
