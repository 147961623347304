import React from 'react'
import { Slip, Status } from '../../../../model/interface/response/history'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { ColorsConstant } from '../../../../assets/colors/Colors'
import WaitingIcon from '../waitingIcon'
import dayjs from 'dayjs'

interface StatusListProps {
    status: Status
    errorMessage?: string
    selected: boolean
    downloaded: boolean
    slip?: Slip
}

const StatusList: React.FC<StatusListProps> = (props) => {
    const dateTimeRegex =
        /(\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4} \(.+?\))/

    const errorMessage = props.errorMessage
        ? props.errorMessage.match(dateTimeRegex)
        : ''
    const dateTime = errorMessage
        ? dayjs(errorMessage[0]).format('DD/MM/YYYY HH:mm')
        : ''
    return (
        <>
            {props.status === Status.VERIFIED ? (
                <div
                    className="status-container"
                    style={{ fontWeight: 'bold' }}
                >
                    <CheckCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.success}`,
                        }}
                    />
                    จ่ายเงิน
                </div>
            ) : (
                props?.slip && (
                    <div
                        className="status-container"
                        style={{ fontWeight: 'bold' }}
                    >
                        <CheckCircleFilled
                            style={{
                                fontSize: '32px',
                                color: `${ColorsConstant.success}`,
                            }}
                        />
                        อัพโหลดหลักฐานการโอนเงิน
                    </div>
                )
            )}
            {props.status === Status.WAITING_VERIFY && (
                <div className="status-container">
                    <CloseCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.danger}`,
                        }}
                    />
                    ตรวจสอบหลักฐานการโอน
                </div>
            )}
            {props.status === Status.VERIFYING && (
                <div className="status-container">
                    <WaitingIcon
                        fontSize={32}
                        background={ColorsConstant.warning}
                    />
                    กำลังตรวจสอบหลักฐานการโอน
                </div>
            )}
            {props.status === Status.FAILED && (
                <div className="status-container">
                    <CloseCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.danger}`,
                        }}
                    />
                    เกิดข้อผิดผลาดในการตรวจสอบหลักฐานการโอนเงิน{' '}
                    {!props?.errorMessage
                        ? ''
                        : dateTime === ''
                          ? `(${props?.errorMessage})`
                          : `(สลิปซ้ำ สลิปนี้เคยส่งเข้ามาในระบบเมื่อ ${dateTime} น.)`}
                    <br />
                    กรุณาลองใหม่อีกครั้ง หรือติดต่อแอดมิน
                </div>
            )}
            {props.status === Status.UNVERIFIED && (
                <div className="status-container">
                    <CloseCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.danger}`,
                        }}
                    />
                    แอดมินไม่อนุมัติหลักฐานการโอนเงิน
                </div>
            )}
            <div
                className="status-container"
                style={{ fontWeight: `${props.selected ? 'bold' : ''}` }}
            >
                {props.selected ? (
                    <CheckCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.success}`,
                        }}
                    />
                ) : (
                    <CloseCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.danger}`,
                        }}
                    />
                )}
                เลือกไฟล์
            </div>

            <div
                className="status-container"
                style={{ fontWeight: `${props.downloaded ? 'bold' : ''}` }}
            >
                {props.downloaded ? (
                    <CheckCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.success}`,
                        }}
                    />
                ) : (
                    <CloseCircleFilled
                        style={{
                            fontSize: '32px',
                            color: `${ColorsConstant.danger}`,
                        }}
                    />
                )}
                ดาวน์โหลดไฟล์
            </div>
        </>
    )
}

export default StatusList
