/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { BlogData } from '../../../../model/interface/response/blog'
import { Image, Typography } from 'antd'
import './index.scss'
import { useNavigate } from 'react-router-dom'

interface BlogCardProps {
    blog: BlogData
}

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
    const { Title, Paragraph } = Typography
    const navigate = useNavigate()

    const handleRecentClicked = (id: string) => {
        navigate(`/blogs/${id}`)
    }
    return (
        <div
            key={blog.id}
            className="blog-card"
            onClick={() => handleRecentClicked(blog.id)}
        >
            <Image
                src={blog.image}
                style={{
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                }}
            />
            <div>
                <Title level={5} ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                    {blog.title}
                </Title>
                {/* <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
                    {blog.shortDesc}
                </Paragraph> */}
                <div
                    className="ellipsis-2"
                    dangerouslySetInnerHTML={{
                        __html: blog.shortDesc,
                    }}
                ></div>
            </div>
        </div>
    )
}

export default BlogCard
