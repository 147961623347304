import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'

import { ContentApiResponse } from '../model/interface/response/content'

export class ContentService {
    private static url = `${prefixApi.content}`

    public static async getAllContent(): Promise<ContentApiResponse> {
        const res = await axiosInstance.get<ContentApiResponse>(`${this.url}s`)
        return res.data
    }
}
