import OptionsPage from './pages/options'
import React from 'react'
import {
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom'
import { RoutePath } from './model/enum/route'
import AppLayout from './layout/app.layout'
import HomePage from './pages/home'
import BlogPage from './pages/blog'
import ProductPage from './pages/product'
import ProductDetail from './pages/product_details'
import CreateAccountPage from './pages/create_account'
import ProfilePage from './pages/profile'
import HistoryPage from './pages/history_page'
import { BlogDetail } from './pages/blogDetail'
import OrderDetailPage from './pages/order_detail'
import { AuthProvider } from './contexts/AuthContext'
import LinePage from './pages/line'
import PreferredPage from './pages/preferred_option'

const AppRouter = (): JSX.Element => {
    const pageRoutes = createBrowserRouter(
        createRoutesFromElements(
            <Route
                element={
                    <AuthProvider>
                        <Outlet />
                    </AuthProvider>
                }
            >
                <Route path="*" element={<Navigate to={`/`} replace />} />

                <Route path={RoutePath.CREATE_ACCOUNT}>
                    <Route
                        id="create"
                        path=""
                        element={<CreateAccountPage />}
                    />
                </Route>

                <Route path="/line">
                    <Route id="line" path="" element={<LinePage />} />
                </Route>

                <Route path="/" element={<AppLayout />}>
                    <Route path={`/`} element={<Outlet />}>
                        <Route id="home" path="" element={<HomePage />} />
                    </Route>

                    <Route path={RoutePath.BLOG} element={<Outlet />}>
                        <Route id="blog" path="" element={<BlogPage />} />
                        <Route
                            id="blog_detail"
                            path=":id"
                            element={<BlogDetail />}
                        />
                    </Route>

                    <Route path={RoutePath.PRODUCT} element={<Outlet />}>
                        <Route id="product" path="" element={<ProductPage />} />
                        <Route
                            id="product_detail"
                            path=":id"
                            element={<ProductDetail />}
                        />
                    </Route>

                    <Route
                        path={RoutePath.PREFERRED_OPTION}
                        element={<Outlet />}
                    >
                        <Route
                            id="perferred-option"
                            path=""
                            element={<PreferredPage />}
                        />
                    </Route>

                    <Route path={RoutePath.HISTORY} element={<Outlet />}>
                        <Route id="history" path="" element={<HistoryPage />} />
                    </Route>
                    <Route path={RoutePath.OPTIONS} element={<Outlet />}>
                        <Route
                            id="options"
                            path=":id"
                            element={<OptionsPage />}
                        />
                    </Route>

                    <Route path={RoutePath.PROFILE} element={<Outlet />}>
                        <Route id="profile" path="" element={<ProfilePage />} />
                    </Route>

                    <Route path={RoutePath.ORDER} element={<Outlet />}>
                        <Route
                            id="order"
                            path=":id"
                            element={<OrderDetailPage />}
                        />
                    </Route>
                </Route>
            </Route>
        )
    )

    return <RouterProvider router={pageRoutes} />
}

export default AppRouter
