import React from 'react'
import { useNavigate } from 'react-router-dom'
import './index.scss'
import { BlogData } from '../../../../../../model/interface/response/blog'

interface BlogAndProductContentProps extends BlogData {
    current: string
}

const BlogAndProductContent = (
    props: BlogAndProductContentProps
): JSX.Element => {
    const navigate = useNavigate()
    const { image, title, shortDesc, id, current } = props
    const handleClick = (id: string) => {
        navigate(current === 'blogs' ? `/blogs/${id}` : `/products/${id}`)
    }
    return (
        <div
            style={{ width: '100%', marginBottom: '20px', cursor: 'pointer' }}
            onClick={() => handleClick(id)}
        >
            <img
                alt={id}
                src={image}
                style={{
                    width: '100%',
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                }}
            />
            <h2 className="title">{title}</h2>
            <div
                className="description"
                dangerouslySetInnerHTML={{
                    __html: shortDesc,
                }}
            ></div>
        </div>
    )
}

export default BlogAndProductContent
