import React, { useEffect, useState } from 'react'
import { PageLayout } from '../../components/page-layout'
import { Empty, PaginationProps, Tabs, TabsProps } from 'antd'
import { ProductService } from '../../services/product.service'
import { ProductResponse } from '../../model/interface/response/products'
import Title from 'antd/es/typography/Title'
import { MasterDataService } from '../../services/masterData.service'
import { MasterDataField } from '../../model/enum/masterData'
import './index.scss'
import ProductLayout from '../blog/components/product-layout'

const ProductPage = (): JSX.Element => {
    const [allProduct, setAllProduct] = useState<ProductResponse>()
    const [category, setCategory] = useState<TabsProps['items']>([])
    const [loading, setLoading] = useState(false)
    const [selectTab, setSelectTab] = useState('all')
    const [pagination, setPagination] = useState<{
        page: number
        pageSize: number
    }>({ page: 1, pageSize: 10 })

    const { page, pageSize } = pagination

    const fetchProductByPageAndPageSize = async (
        page: number,
        pageSize: number,
        selectTab: string
    ) => {
        try {
            setLoading(true)
            const allProduct = await ProductService.getAllProducts(
                page,
                pageSize,
                selectTab
            )
            setAllProduct(allProduct)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
            setLoading(false)
        }
    }

    const onTabsChange = async (key: string) => {
        setSelectTab(key)
        setPagination({ page: 1, pageSize })
        await fetchProductByPageAndPageSize(1, pageSize, key)
    }

    const onPaginationChange: PaginationProps['onChange'] = async (
        current,
        pageSize
    ) => {
        setPagination({ page: current, pageSize })
        await fetchProductByPageAndPageSize(current, pageSize, selectTab)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)

                // Fetch products
                await fetchProductByPageAndPageSize(page, pageSize, selectTab)

                // Fetch categories
                const categoryResponse =
                    await MasterDataService.getMasterDataByRefCode(
                        'product_category'
                    )
                const tabsData = categoryResponse.data.map((category) => ({
                    key: category[MasterDataField.ID],
                    label: category[MasterDataField.MASTER_DATA_NAME],
                }))
                const categoryTabs = [
                    { key: 'all', label: 'ทั้งหมด' },
                    ...tabsData,
                ]
                setCategory(categoryTabs)

                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error)
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return (
        <PageLayout>
            <Title level={3} style={{ marginBottom: '20px' }}>
                สินค้าทั้งหมด
            </Title>
            <Tabs items={category} onChange={onTabsChange} />
            {allProduct?.data.length !== 0 ? (
                <ProductLayout
                    data={
                        allProduct || {
                            data: [],
                            total: 0,
                            page: 1,
                            pageSize: 10,
                        }
                    }
                    onPaginationChange={onPaginationChange}
                    current="product"
                    loading={loading}
                />
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="ไม่มีข้อมูล"
                />
            )}
        </PageLayout>
    )
}

export default ProductPage
