/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import '../../index.scss'
import { Button, Tabs, TabsProps } from 'antd'
import { IMasterDataItem } from '../../../../model/interface/response/masterData'
import { ProductService } from '../../../../services/product.service'
import { IAnotherProductResponse } from '../../../../model/interface/response/products'
import { ProductItem } from '../anotherProductItem'
import { useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'

interface AnotherProductProps {
    category: IMasterDataItem[]
}

const AnotherProduct: React.FC<AnotherProductProps> = ({ category }) => {
    const navigate = useNavigate()
    const [loadedProducts, setLoadedProducts] = React.useState<
        IAnotherProductResponse[]
    >([])
    const handleProductDetail = (id: string) => {
        navigate(`/products/${id}`)
    }
    const handleAllProducts = () => {
        navigate(`/products`)
    }
    React.useEffect(() => {
        const fetchProducts = async () => {
            const promises = category.map(async (item) => {
                try {
                    const anotherProductRes =
                        await ProductService.getAnotherProductByCategoryId(
                            item.id
                        )
                    return anotherProductRes as IAnotherProductResponse
                } catch (error) {
                    console.error('Error fetching another product:', error)
                    return null
                }
            })

            const resolvedProducts = await Promise.all(promises)
            const filteredProducts = resolvedProducts.filter(
                (item) => item !== null && item.data.length !== 0
            ) as IAnotherProductResponse[]

            setLoadedProducts(filteredProducts)
        }

        fetchProducts()
    }, [category])
    const items: TabsProps['items'] = loadedProducts.map((item) => {
        return {
            key: item.category.id,
            label: item.category.name,
            children: (
                <div className="group-anothers">
                    {item.data.map((product) => {
                        return (
                            <ProductItem
                                key={product.id}
                                id={product.id}
                                title={product.title}
                                category={item.category.name}
                                banner={product.banner}
                                onClick={handleProductDetail}
                            />
                        )
                    })}
                </div>
            ),
        }
    })
    return (
        <>
            {items.length > 0 && (
                <div className="another-product-container">
                    <Title level={2}>สินค้าอื่นๆ</Title>
                    <Tabs items={items} />
                    <Button
                        type="primary"
                        shape="round"
                        onClick={handleAllProducts}
                    >
                        ผลิตภัณฑ์ทั้งหมด
                    </Button>
                </div>
            )}
        </>
    )
}

export default AnotherProduct
