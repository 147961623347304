export enum MasterDataField {
    ID = 'id',
    TYPE_ID = 'typeId',
    TYPE_NAME = 'typeName',
    MASTER_DATA_NAME = 'masterDataName',
    DESCRIPTION = 'description',
    BANNER = 'banner',
    CREATE_DATE = 'createDate',
    UPDATE_DATE = 'updateDate',
    CREATE_BY = 'createBy',
}
