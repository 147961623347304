import {
    LoginRequest,
    LoginResponse,
    LoginWithLineRequest,
} from '../model/interface/response/auth'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class AuthService {
    private static url = `${prefixApi.auth}`

    public static async login(data: LoginRequest): Promise<LoginResponse> {
        const res = await axiosInstance.post<LoginResponse>(
            `${this.url}/login`,
            data
        )
        return res.data
    }

    public static async loginWithLine(
        data: LoginWithLineRequest
    ): Promise<LoginResponse> {
        const res = await axiosInstance.post<LoginResponse>(
            `${this.url}/login-with-line`,
            data
        )
        return res.data
    }
}
