import {
    IAnotherProductResponse,
    IProductRecommendationResponse,
    MergeFileRequest,
    ProductData,
    ProductResponse,
    ChoiceProductData,
} from '../model/interface/response/products'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class ProductService {
    private static url = `${prefixApi.product}`
    private static file_merge = `${prefixApi.file_merge}`
    private static verify = `${prefixApi.verify}`

    public static async getAllProducts(
        page?: number,
        pageSize?: number,
        masterDataItemId?: string
    ): Promise<ProductResponse> {
        const queryParams = new URLSearchParams()

        if (page) queryParams.append('page', page.toString())
        if (pageSize) queryParams.append('pageSize', pageSize.toString())
        if (
            typeof masterDataItemId === 'string' &&
            masterDataItemId !== 'all'
        ) {
            queryParams.append('masterDataItemId', masterDataItemId)
        }

        const url = `${this.url}s${queryParams.toString() ? `?${queryParams.toString()}` : ''}`
        const res = await axiosInstance.get<ProductResponse>(url)

        return res.data
    }

    public static async getProductNotPaidById(
        id: string
    ): Promise<ProductData> {
        const res = await axiosInstance.get<ProductData>(
            `${this.url}/${id}`
        )
        return res.data
    }

    public static async getProductPaidById(refId: string, productId: string): Promise<ChoiceProductData> {
        const res = await axiosInstance.get<ChoiceProductData>(`${this.verify}/product?ref=${refId}&productId=${productId}`)
        return res.data
    }

    public static async getFileMerge(refId: string): Promise<Blob> {
        const res = await axiosInstance.get(`${this.file_merge}/get-file-merge?refId=${refId}`, { responseType: 'blob' });
        return res.data;
    }

    public static async getProductRecommendation(): Promise<IProductRecommendationResponse> {
        const res = await axiosInstance.get<IProductRecommendationResponse>(
            `${this.url}/recommendation`
        )
        return res.data
    }

    public static async getProductsByCategoryId(
        categoryId: string
    ): Promise<ProductResponse> {
        const res = await axiosInstance.get<ProductResponse>(
            `${this.url}?categoryId=${categoryId}`
        )
        return res.data
    }

    public static async getAnotherProductByCategoryId(
        categoryId: string
    ): Promise<IAnotherProductResponse> {
        const res = await axiosInstance.get<IAnotherProductResponse>(
            `${this.url}/another?categoryId=${categoryId}`
        )
        return res.data
    }


    public static async getProductById(
        productId: string
    ): Promise<ProductData> {
        const res = await axiosInstance.get<ProductData>(
            `${this.url}/${productId}`
        )
        return res.data
    }

    public static async mergeFile(
        urls: MergeFileRequest
    ): Promise<ProductResponse> {
        const res = await axiosInstance.put<ProductResponse>(
            `${this.file_merge}/file-merge`,
            urls
        )
        return res.data
    }
}
