export const ColorsConstant = {
    primary: '#000000',
    secondary: '#ffffff',
    primary_active: '#999999',
    primary_light: '#cccccc',
    primary_inverse: '#f4f4f4',
    success: '#52c41a',
    success_hover: '#73d13d',
    success_active: '#389e0d',
    success_light: '#52c41a',

    hover: '#535353',
    color_primary_bg: '#E7E7E7',
    color_link_hover: '#767676',

    button_hover: '#4096ff',
    info: '#1890ff',
    info_hover: '#40a9ff',
    info_active: '#096dd9',
    info_light: '#eee5ff',

    warning: '#faad14',
    warning_hover: '#ffc53d',
    warning_active: '#d48806',
    warning_light: '#fff4de',

    danger: '#ff4d4f',
    danger_hover: '#ff7875',
    danger_active: '#d9363e',
    danger_light: '#ffe2e5',

    navbar_color_background: '#ffffff',
    navbar_color_text: '#000000',

    footer_color_background: '#ffffff',
    footer_color_text: '#000000',
}

export const TextBase = {
    fontFamily: '"Kanit", sans-serif',
    font_size: 16,
}

export const important_component = {
    colorPrimary: ColorsConstant.primary,
    colorBgBase: ColorsConstant.secondary,
    colorError: ColorsConstant.danger,
    colorInfo: ColorsConstant.info,
    colorLink: ColorsConstant.info,
    colorSuccess: ColorsConstant.success,
    colorTextBase: ColorsConstant.primary,
    colorWarning: ColorsConstant.warning,
    fontSize: TextBase.font_size,
    fontFamily: TextBase.fontFamily,
    colorPrimaryHover: ColorsConstant.hover,
    colorPrimaryBg: ColorsConstant.color_primary_bg,
    colorLinkHover: ColorsConstant.color_link_hover,
}

export const component_all = {
    Layout: {
        footerBg: ColorsConstant.footer_color_text,
        colorText: ColorsConstant.footer_color_background,
    },
}
