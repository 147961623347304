import React, { useEffect, useState } from 'react'
import '../order_detail/orderDetailPage.scss'
import { Button, Result, Steps } from 'antd'

import LoadingBox from '../../components/loading'
import ChoicePage from './components/preferred_option'
import { ProductService } from '../../services/product.service'
import { ChoiceProductData } from '../../model/interface/response/products'
import DownloadPDFPage from './components/dowload_pdf'
import { useLocation, useNavigate } from 'react-router-dom'
import { VerifiedData } from '../../model/interface/response/verify'
import { PaidService } from '../../services/paid.service'
import { AxiosError } from 'axios'

const PreferredPage = (): JSX.Element => {
    const [current, setCurrent] = useState(0)

    const next = () => {
        setCurrent(current + 1)
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    const [loading, setLoading] = useState<boolean>(false)
    const [productDetail, setProductDetail] = useState<ChoiceProductData>()
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const productId = urlParams.get('productId')
    const refId = urlParams.get('ref')
    const [verifiedData, setVerifiedData] = React.useState<VerifiedData>()
    const navigate = useNavigate()
    const [isVerified, setIsVerified] = useState(false)
    const [forBidden, setForBidden] = useState(false)
    const [badRequest, setBadRequest] = useState(false)

    useEffect(() => {
        if (refId) {
            handleVerifyRef(refId)
        }
    }, [refId])

    const handleVerifyRef = async (refId: string) => {
        try {
            const verifiedRes = await PaidService.getVerify(refId)
            setVerifiedData(verifiedRes.data)
            setIsVerified(true)
        } catch (error) {
            setVerifiedData(undefined)
            setIsVerified(false)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!isVerified) return
            try {
                if (refId && productId) {
                    setLoading(true)
                    const productDetailData =
                        await ProductService.getProductPaidById(
                            refId,
                            productId
                        )
                    setProductDetail(productDetailData)
                    setLoading(false)
                }
            } catch (error) {
                switch ((error as AxiosError).response?.status) {
                    case 400:
                        setBadRequest(true)
                        break
                    case 403:
                        setForBidden(true)
                        break
                }
                setLoading(false)
            }
        }
        fetchData()
    }, [isVerified])

    const steps = [
        {
            title: 'ขั้นตอนที่ 1',
            content: (
                <ChoicePage
                    current={current}
                    onNext={() => next()}
                    onPrev={() => prev()}
                    data={productDetail}
                    numberState={1}
                />
            ),
            description: 'เลือกไฟล์ PDF',
        },
        {
            title: 'ขั้นตอนที่ 2',
            content: (
                <DownloadPDFPage
                    current={current}
                    numberState={2}
                    productId={productId}
                />
            ),
            description: 'ดาวน์โหลดไฟล์ PDF',
        },
    ]
    return (
        <>
            {!verifiedData || !verifiedData?.verified || forBidden ? (
                <Result
                    status="warning"
                    title="ลูกค้ากรุณาชำระเงินก่อนทำรายการ"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                navigate(`/order/${productId}`)
                            }}
                        >
                            ชำระเงิน
                        </Button>
                    }
                />
            ) : (verifiedData?.verified && verifiedData?.downloaded) || badRequest ? (
                <Result
                    status="warning"
                    title="ลูกค้าดาวน์โหลดไฟล์เรียบร้อยแล้วไม่สามารถดาวน์โหลดซ้ำได้"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                navigate('/history')
                            }}
                        >
                            ประวัติการโหลดไฟล์
                        </Button>
                    }
                />
            ) : (
                <>
                    {!loading && productDetail ? (
                        <div className="perferred-option-container">
                            <Steps
                                current={current}
                                size="small"
                                items={steps}
                            />
                            {steps[current].content}
                        </div>
                    ) : (
                        <LoadingBox />
                    )}
                </>
            )}
        </>
    )
}
export default PreferredPage
