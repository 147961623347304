import React from 'react'
import { Image, Typography } from 'antd'
import { Content } from '../../../../model/interface/response/content'

interface ContentsProps {
    content: Content[]
}

const ContentBox: React.FC<ContentsProps> = ({ content }) => {
    const { Title } = Typography
    return (
        <div className="group-content-box">
            {content.map((item, index) => (
                <div
                    className={`content-box ${index % 2 == 0 ? 'row' : 'row-reverse'}`}
                    key={item.id}
                >
                    <Image
                        src={item.imageUrl}
                        className="img"
                        alt={item.title}
                    />
                    <div className="container-text-box">
                        <Title level={3}>{item.title}</Title>
                        <div
                            style={{ color: 'black' }}
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        ></div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ContentBox
