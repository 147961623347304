import { BannerResponse } from '../model/interface/response/banners'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class BannerService {
    private static url = `${prefixApi.banner}`

    public static async getAllBanners(): Promise<BannerResponse> {
        const res = await axiosInstance.get<BannerResponse>(`${this.url}s`)
        return res.data
    }
}
