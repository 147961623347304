import React, { useState, useEffect, useRef } from 'react'
import '../banner/banner.scss'
import { useSwipeable } from 'react-swipeable'
import { BannerData } from '../../model/interface/response/banners'

interface ImageSliderProps {
    images: BannerData[]
}

const ImageBanner: React.FC<ImageSliderProps> = ({ images = [] }) => {
    const [slide, setSlide] = useState(0)
    const [isHovered, setIsHovered] = useState(false)
    const [carouselWidth, setCarouselWidth] = useState<number | null>(null)
    const carouselRef = useRef<HTMLDivElement>(null)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const nextSlide = () => {
        setSlide((prevSlide) =>
            prevSlide === images.length - 1 ? 0 : prevSlide + 1
        )
    }

    const prevSlide = () => {
        setSlide((prevSlide) =>
            prevSlide === 0 ? images.length - 1 : prevSlide - 1
        )
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => prevSlide(),
    })

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSlide((prevSlide) =>
                prevSlide === images.length - 1 ? 0 : prevSlide + 1
            )
        }, 5000)

        return () => clearInterval(intervalId)
    }, [images.length])

    useEffect(() => {
        const updateCarouselWidth = () => {
            if (carouselRef.current) {
                setCarouselWidth(carouselRef.current.clientWidth)
            }
        }
        updateCarouselWidth()
        window.addEventListener('resize', updateCarouselWidth)
        return () => {
            window.removeEventListener('resize', updateCarouselWidth)
        }
    }, [])

    return (
        <div
            className="carousel"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...handlers}
            ref={carouselRef}
        >
            <div className="slider"></div>
            {images.map((item, index) => {
                return (
                    <img
                        src={
                            carouselWidth && carouselWidth < 768
                                ? item.imageUrlMobile || item.imageUrlDesktop
                                : item.imageUrlDesktop
                        }
                        alt={item.imageName}
                        key={item.id}
                        onClick={() => {
                            if (item.deepLink) {
                                const url = item.deepLink.startsWith('http')
                                    ? item.deepLink
                                    : `https://${item.deepLink}`
                                window.location.href = url
                            }
                        }}
                        className={`${slide === index ? 'slide img-scale' : 'slide slide-hidden img-scale'} ${
                            item.deepLink ? 'pointer' : ''
                        }`}
                    />
                )
            })}
            <button
                className={`prev ${isHovered ? 'show' : ''}`}
                onClick={prevSlide}
            >
                &#10094;
            </button>
            <button
                className={`next ${isHovered ? 'show' : ''}`}
                onClick={nextSlide}
            >
                &#10095;
            </button>
            <ul className="dots">
                {images.map((_, idx) => {
                    return (
                        <li
                            key={idx}
                            className={slide === idx ? 'active' : ''}
                            onClick={() => setSlide(idx)}
                        ></li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ImageBanner
