import React, { useEffect, useState } from 'react'
import { PageLayout } from '../../components/page-layout'
import './index.scss'
import { BlogData } from '../../model/interface/response/blog'
import { BlogService } from '../../services/blog.service'
import LoadingBox from '../../components/loading'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import BlogCard from './components/blogCard'
import Blogcontents from './components/blogContents'
import { Image } from 'antd'

export const BlogDetail: React.FC = () => {
    const { id } = useParams()
    const [blog, setBlog] = useState<BlogData>()
    const [blogs, setBlogs] = useState<BlogData[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        const fetchBlogById = async () => {
            try {
                setLoading(true)
                const blogRes = await BlogService.getBlogById(id)
                setBlog(blogRes)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchBlogById()
        const fetchBlogs = async () => {
            try {
                setLoading(true)
                const blogRecommend = await BlogService.getAllBlogs(
                    undefined,
                    5
                )
                setBlogs(blogRecommend.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchBlogs()
    }, [id])
    return (
        <PageLayout>
            {!loading && blog ? (
                <div className="row-layout">
                    <div className="content-layout">
                        <Image
                            width={'100%'}
                            style={{
                                aspectRatio: '16/9',
                                objectFit: 'cover',
                            }}
                            src={blog.image}
                            alt={blog.image}
                        />
                        <h1>{blog.title}</h1>
                        <p>
                            {dayjs(blog?.updateDate)
                                .locale('th')
                                .format('DD MMM YYYY')}
                        </p>
                        {blog.content.map((contents, index) => (
                            <div key={index} style={{ padding: '16px 0' }}>
                                <Blogcontents
                                    type={contents.type}
                                    description={contents.description}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="blogs-layout">
                        <h3>บทความที่คุณอาจสนใจ</h3>
                        {blogs.map((item) => (
                            <BlogCard key={item.id} blog={item} />
                        ))}
                    </div>
                </div>
            ) : (
                <LoadingBox />
            )}
        </PageLayout>
    )
}
