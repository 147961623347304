export enum RoutePath {
    AUTH = "auth",
    CREATE_ACCOUNT = "create",
    HOME = "home",
    BLOG = "blogs",
    PRODUCT = "products",
    PREFERRED_OPTION = "preferred-option",
    DOWNLOAD_PDF = "download-pdf",
    OPTIONS = "options",
    PROFILE = "profile",
    HISTORY = "history",
    ORDER = 'order',
    PAYMENT = 'payment'
  }
