import React from 'react'

interface ProductItemProps {
    id: string
    title: string
    category: string
    banner: string
    onClick: (id: string) => void
}

export const ProductItem = (props: ProductItemProps): JSX.Element => {
    const { id, title, category, banner, onClick } = props
    return (
        <div className="container" key={id} onClick={() => onClick(id)}>
            <div className="background"></div>
            <div className="text-container">
                <div
                    className="another-product-header-text"
                    style={{ overflowWrap: 'break-word' }}
                >
                    {title}
                </div>
                <div
                    className="women-text"
                    style={{ overflowWrap: 'break-word' }}
                >
                    {category}
                </div>
            </div>
            <img className="image" src={banner} alt={title} />
        </div>
    )
}
