import React from 'react'
// import { Content } from 'antd/es/layout/layout'
import { Button, Form, Input, Typography } from 'antd'
import { useAuth } from '../../contexts/AuthContext'
import { EditAccountRequest } from '../../model/interface/response/customer'
import styled from 'styled-components'
import { validateMessages } from '../../model/enum/message'

const FormProfile = styled(Form)`
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const ProfilePage: React.FC = () => {
    const { user, editAccount } = useAuth()
    const [form] = Form.useForm()

    const [isEdit, setisEdit] = React.useState(false)

    React.useEffect(() => {
        if (user) {
            form.setFieldsValue({
                displayName: user.displayName,
                email: user.email,
            })
        }
    }, [form, user])

    const onFinish = async (values: unknown) => {
        const typedValues = values as EditAccountRequest
        editAccount(typedValues)
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '0 24px',
                justifyContent: 'center',
            }}
        >
            <Typography.Title style={{ textAlign: 'center' }}>
                ข้อมูลส่วนบุคคลของคุณ
            </Typography.Title>
            <Typography.Paragraph>
                รายละเอียดข้อมูลส่วนบุคคล
            </Typography.Paragraph>
            {user && (
                <FormProfile
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={onFinish}
                    initialValues={{
                        displayName: user?.displayName,
                        email: user?.email,
                    }}
                >
                    <Form.Item
                        name={['displayName']}
                        label="ชื่อผู้ใช้"
                        rules={[
                            {
                                required: true,
                                message: validateMessages.required_name,
                            },
                            {
                                pattern: /^[a-zA-Z0-9_ ]{1,}$/,
                                message: validateMessages.display_name,
                            },
                        ]}
                    >
                        <Input
                            disabled={!isEdit}
                            placeholder="กรอกชื่อที่ใช้แสดงในเว็บไซต์"
                        />
                    </Form.Item>
                    <Form.Item
                        name={['email']}
                        label="อีเมล"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกอีเมล',
                            },
                            {
                                pattern: /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                message:
                                    'กรุณากรอกอีเมลเป็นตัวพิมพ์เล็กและรูปแบบที่ถูกต้อง',
                            },
                        ]}
                    >
                        <Input
                            disabled={!isEdit}
                            placeholder="ตัวอย่าง example@email.com"
                        />
                    </Form.Item>
                    {isEdit && (
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            htmlType="submit"
                        >
                            ยืนยัน
                        </Button>
                    )}
                    {!isEdit && (
                        <Button
                            style={{ width: '100%' }}
                            onClick={() => setisEdit(true)}
                        >
                            แก้ไขข้อมูล
                        </Button>
                    )}
                </FormProfile>
            )}
        </div>
    )
}

export default ProfilePage
