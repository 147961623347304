import React from 'react'
import './App.css'
import AppRouter from './application.route'
import { AppProvider } from './contexts/AppContext'
import { ConfigProvider } from 'antd'
import { component_all, important_component } from './assets/colors/Colors'

const App = (): JSX.Element => {
    return (
        <ConfigProvider
            theme={{
                token: important_component,
                components: component_all,
            }}
        >
            <AppProvider>
                <AppRouter />
            </AppProvider>
        </ConfigProvider>
    )
}

export default App
