/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Layout } from 'antd'
import NavbarApp from '../components/app.navbar'
import { Outlet } from 'react-router-dom'
import FooterApp from '../components/app.footer'
import { Content } from 'antd/es/layout/layout'
import '../assets/scss/components/_layout.scss'
import { useAppContext } from '../contexts/AppContext'
import { MasterDataService } from '../services/masterData.service'
import { IMasterDataItem } from '../model/interface/response/masterData'
import Navbar from '../components/navbar'

const AppLayout: React.FC = () => {
    const { menuOpen, updateMenuOpen } = useAppContext()
    const handleOnClick = () => {
        updateMenuOpen(false)
    }

    const [logo, setLogo] = React.useState<IMasterDataItem[]>([])
    const fetchlogo = React.useCallback(async () => {
        try {
            const categoryResponse =
                await MasterDataService.getMasterDataByRefCode('logo')
            setLogo(categoryResponse.data)
        } catch (error) {
            console.error('Error fetching logo:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchlogo()
    }, [fetchlogo])

    return (
        <Layout className="layout">
            {/* <NavbarApp logo={logo[0]?.banner}></NavbarApp> */}
            <Navbar logo={logo[0]?.banner}></Navbar>
            {/* <div
                className={`${menuOpen ? 'menu-open' : 'non-open'}`}
                onClick={handleOnClick}
            >
                {' '}
            </div> */}
            <Content>
                <Outlet />
            </Content>
            <FooterApp logo={logo[0]?.banner}></FooterApp>
        </Layout>
    )
}

export default AppLayout
