/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import '../../index.scss'
import { useNavigate } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { BlogData } from '../../../../model/interface/response/blog'
import Title from 'antd/es/typography/Title'

interface BlogsProps {
    blogs: BlogData[]
}
// const { Paragraph } = Typography
const Blogs: React.FC<BlogsProps> = ({ blogs }) => {
    const navigate = useNavigate()

    const handleAllBlogs = () => {
        navigate(`/blogs`)
    }
    const handleProductDetail = (id: string) => {
        navigate(`/blogs/${id}`)
    }

    return (
        <div className="blogs">
            <div className="group-blogs">
                {blogs.map((blog) => (
                    <div
                        key={blog.id}
                        className="blog-container"
                        onClick={() => handleProductDetail(blog.id)}
                    >
                        <img src={blog.image} alt="" />
                        <div className="blog-text-container">
                            <Title
                                level={4}
                                style={{ margin: '0px' }}
                                ellipsis={{ rows: 3 }}
                            >
                                {blog.title}
                            </Title>

                            {/* <Paragraph
                                className="text-description"
                                ellipsis={{ rows: 3 }}
                            >
                                {blog.shortDesc}
                            </Paragraph> */}
                            <div
                                className="text-description"
                                dangerouslySetInnerHTML={{
                                    __html: blog.shortDesc,
                                }}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
            <Button type="primary" shape="round" onClick={handleAllBlogs} ghost>
                บทความทั้งหมด
            </Button>
        </div>
    )
}

export default Blogs
