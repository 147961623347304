/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from '../../contexts/AuthContext'
import { IconUser } from '../app.iconuser'
import ModalLogin from '../app.login.modal'
import MenuIcon from './menuIcon'

interface NavbarProps {
    logo: string
}

const Nav = styled.div`
    position: sticky;
    top: 0px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 999;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    ul.menu {
        margin: 0;
        display: flex;
        list-style: none;
        gap: 24px;
        align-items: center;
    }
    .menu a {
        color: black;
    }
    .menu a.mobile-menu {
        display: none;
    }
    .menu a:hover {
        color: #636363;
    }

    .menu ul {
        display: inline;
    }

    @media (max-width: 786px) {
        ul.menu {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 0;
            padding: 0;
            gap: 0;
            overflow: hidden;
            background-color: white;
            flex-direction: column;
            align-items: center;
            box-shadow: 0rem 1rem 1rem rgba(0, 0, 0, 0.1);
            transition: 0.3s ease;
        }
        .menu a {
            width: 100%;
            padding: 8px 0;
            text-align: center;
            transition: 0.1s ease;
        }
        .menu a:hover {
            color: white;
            background-color: #333;
        }
        .menu button {
            display: none;
        }
        .menu.menu-active {
            height: calc(40px * var(--i));
        }
        .menu a.mobile-menu {
            display: inline;
        }
    }
`

const Navbar: React.FC<NavbarProps> = ({ logo = '' }) => {
    const [openMenu, setOpenMenu] = React.useState(false)

    const navigate = useNavigate()
    const { logout, user } = useAuth()

    const handleButtonCreate = () => {
        navigate('/create')
    }

    return (
        <Nav>
            <img
                src={logo}
                alt=""
                style={{
                    width: '122px',
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                }}
                onClick={() => navigate('/')}
            />

            <ul
                className={`menu ${openMenu ? 'menu-active' : ''}`}
                style={{ '--i': user ? 6 : 5 } as React.CSSProperties}
            >
                <a href="/">หน้าแรก</a>
                <a href="/products">ผลิตภัณฑ์</a>
                <a href="/blogs">บทความ</a>
                {user ? (
                    <>
                        <a className="mobile-menu" href="/profile">
                            โปรไฟล์
                        </a>
                        <a className="mobile-menu" href="/history">
                            ประวัติการซื้อ
                        </a>
                        <a className="mobile-menu" onClick={() => logout()}>
                            ออกจากระบบ
                        </a>
                        <IconUser />
                    </>
                ) : (
                    <>
                        <ModalLogin
                            modal_label="เข้าสู่ระบบ"
                            where_modal="navbar"
                        />
                        <Button type="primary" onClick={handleButtonCreate}>
                            สร้างบัญชีผู้ใช้
                        </Button>
                        <a className="mobile-menu" href="/create">
                            สร้างบัญชีผู้ใช้
                        </a>
                    </>
                )}
            </ul>

            <MenuIcon openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </Nav>
    )
}

export default Navbar
