/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useNotification } from '../../components/app.toast'
import { useAppContext } from '../../contexts/AppContext'
import { useAuth } from '../../contexts/AuthContext'
import { ProductService } from '../../services/product.service'
import { MasterDataService } from '../../services/masterData.service'
import { BannerService } from '../../services/banner.service'
import { BlogService } from '../../services/blog.service'
import { ContentService } from '../../services/content.service'
import { BlogData } from '../../model/interface/response/blog'
import { IProductRecommendation } from '../../model/interface/response/products'
import { BannerData } from '../../model/interface/response/banners'
import { IMasterDataItem } from '../../model/interface/response/masterData'
import { Content } from '../../model/interface/response/content'
import { Typography } from 'antd'
import ImageBanner from '../../components/banner/banner'
import AnotherProduct from './components/anotherProducts'
import Blogs from './components/blogs'
import ContentBox from './components/contentBox'
import './index.scss'
import '../../assets/scss/components/_button.scss'

const HomePage = (): JSX.Element => {
    const navigate = useNavigate()
    const { openNotificationWithIcon, contextHolder } = useNotification()
    const { notificationState, updateNotificationState } = useAppContext()
    const { user } = useAuth()
    const handleProductDetail = (id: string) => {
        navigate(`/products/${id}`)
    }

    React.useEffect(() => {
        if (notificationState) {
            console.log('Notification State is true')
            openNotificationWithIcon('success', 'success_create_account')
            updateNotificationState(!notificationState)
        }
    }, [notificationState, openNotificationWithIcon, updateNotificationState])

    // fetch Banners
    const [banner, setBanner] = React.useState<BannerData[]>([])
    const fetchBanners = React.useCallback(async () => {
        try {
            const bannerResponse = await BannerService.getAllBanners()
            setBanner(bannerResponse.data)
        } catch (error) {
            console.error('Error fetching banners data:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchBanners()
    }, [fetchBanners])

    // fetch Products Recommendation
    const [productsRecommendation, setProductsRecommendation] = React.useState<
        IProductRecommendation[]
    >([])
    const fetchProductRecommendation = React.useCallback(async () => {
        try {
            const productRecommendationResponse =
                await ProductService.getProductRecommendation()
            setProductsRecommendation(productRecommendationResponse.data)
        } catch (error) {
            console.error('Error fetching product recommendation:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchProductRecommendation()
    }, [fetchProductRecommendation])

    // fetch Content
    const [content, setContent] = React.useState<Content[]>([])
    const fetchContent = React.useCallback(async () => {
        try {
            const contentResponse = await ContentService.getAllContent()
            setContent(contentResponse.data)
        } catch (error) {
            console.error('Error fetching content:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchContent()
    }, [fetchContent])

    // fetch Category
    const [category, setCategory] = React.useState<IMasterDataItem[]>([])
    const fetchCategory = React.useCallback(async () => {
        try {
            const categoryResponse =
                await MasterDataService.getMasterDataByRefCode(
                    'product_category'
                )
            setCategory(categoryResponse.data)
        } catch (error) {
            console.error('Error fetching category:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchCategory()
    }, [fetchCategory])

    // Fetch Blog Recommend
    const [blogs, setBlogs] = React.useState<BlogData[]>([])
    const fetchBlogs = React.useCallback(async () => {
        try {
            const blogRecommend = await BlogService.getAllBlogs(undefined, 3)
            setBlogs(blogRecommend.data)
        } catch (error) {
            console.error('Error fetching blog recommend:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchBlogs()
    }, [fetchBlogs])

    return (
        <>
            {contextHolder}
            <div className="home-page">
                {banner.length > 0 && <ImageBanner images={banner} />}
                {productsRecommendation.length > 0 && (
                    <div className="group-recomment-box">
                        <div className="recommen-products-container">
                            <Typography.Title
                                level={2}
                                style={{ margin: '0px' }}
                            >
                                สินค้าแนะนำ
                            </Typography.Title>
                            <div className="image-products hover-zoom-in">
                                {productsRecommendation.map((product) => (
                                    <div key={product.id}>
                                        <figure>
                                            <img
                                                key={product.id}
                                                onClick={() =>
                                                    handleProductDetail(
                                                        product.id
                                                    )
                                                }
                                                className="image-box"
                                                src={product.banner}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {content.length > 0 && <ContentBox content={content} />}
                {category.length > 0 && <AnotherProduct category={category} />}
                {blogs.length > 0 && <Blogs blogs={blogs} />}
            </div>
        </>
    )
}

export default HomePage
