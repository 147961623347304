import React, { useState } from 'react'
import { ProductData } from '../../../model/interface/response/products'
import { Form, Button, Card, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import Title from 'antd/es/typography/Title'
import ModalRedirect from './modal'
import CardAccount from './cardAccount'
import { SlipForm } from './formUpload'
import { PostVerifyQR } from '../../../model/interface/response/payment'
import { PaymentService } from '../../../services/payment.service'
import { useNotification } from '../../../components/app.toast'
import { useNavigate } from 'react-router-dom'

interface PaymentProps {
    current: number
    onPrev: () => void
    data: ProductData
    numberState: number
}

const PaymentPage = (props: PaymentProps): JSX.Element => {
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [typeModal, setTypeModal] = useState<'verifying'>('verifying')
    const [form] = Form.useForm()
    const { openNotificationWithIcon, contextHolder } = useNotification()
    const navigate = useNavigate()

    const onSubmitForm = (formData: PostVerifyQR) => {
        setTypeModal('verifying')
        try {
            pushDataCreate(formData)
        } catch (error) {
            console.error('Error deleting delete:', error)
        }
    }

    const pushDataCreate = async (params: PostVerifyQR) => {
        setVisible(true)
        try {
            const response = await PaymentService.postPaymentVerify(params)
            if (response.data.success == true) {
                setVisible(false)
                openNotificationWithIcon('success', 'success_payment')
                setTimeout(() => {
                    navigate(
                        `/preferred-option?productId=${props.data.id}&ref=${params.docId}`
                    )
                }, 2 * 1000)
            }
        } catch (error) {
            let errorMessage: string = 'An unexpected error occurred'
            if (
                typeof error === 'object' &&
                error !== null &&
                'error' in error
            ) {
                errorMessage = (error as { error: string }).error
            }

            let detailedMessage: string = 'กรุณาตรวจสอบอีกครั้ง'
            if (
                typeof error === 'object' &&
                error !== null &&
                'message' in error
            ) {
                detailedMessage = (error as { message: string }).message
            }

            if (errorMessage === 'failed to verify slip') {
                setVisible(false)
                openNotificationWithIcon(
                    'error',
                    `ไม่สามารถตรวจสอบการชำระเงินได้: ${detailedMessage} กรุณาลองใหม่อีกครั้ง หรือติดต่อแอดมิน`
                )
                setTimeout(() => {
                    navigate(`/history`)
                }, 5 * 1000)
            } else {
                setVisible(false)
                openNotificationWithIcon(
                    'error',
                    `ไม่สามารถตรวจสอบการชำระเงินได้: ${detailedMessage} กรุณาลองใหม่อีกครั้ง หรือติดต่อแอดมิน`
                )
                setTimeout(() => {
                    navigate(`/history`)
                }, 5 * 1000)
            }
        }
    }

    return (
        <>
            {contextHolder}
            <div className="head_content" onClick={() => props.onPrev()}>
                <LeftOutlined />
                <Typography.Paragraph style={{ margin: 0 }}>
                    ย้อนกลับ
                </Typography.Paragraph>
            </div>

            <Title level={3} style={{ margin: 0 }}>
                ชำระเงิน
            </Title>
            <div
                className="text-description"
                dangerouslySetInnerHTML={{
                    __html: props.data?.paymentDetail || '',
                }}
            ></div>
            <Card
                title="สรุปค่าใช้จ่าย"
                size={'small'}
                bordered={false}
                className="summaryCard"
            >
                <div className="summaryContent">
                    <p>ราคารวม</p>
                    <p>{props.data?.price.toLocaleString()} บาท</p>
                </div>
            </Card>
            <CardAccount
                accountName="ธารญา มิ่งขวัญ"
                accountNo="037-8-21987-6"
                bankName="ธนาคารกสิกร"
                bankBranch="สาขา โลตัสโนนม่วงขอนแก่น"
                bankImage={`${process.env.PUBLIC_URL}/images/bankLogo.png`}
            />

            <div>
                <Title level={4} style={{ margin: 0 }}>
                    อัพโหลดหลักฐานการโอนเงิน
                </Title>
                <Title level={4} style={{ margin: 0 }}>
                    ** ห้ามใส่โน๊ตในสลิปการโอนเงิน **
                </Title>
                <Title level={4} style={{ margin: 0 }}>
                    ** ชื่อไฟล์สลิปห้ามเป็นภาษาไทย **
                </Title>
            </div>
            <div className="summaryCard">
                <SlipForm
                    setIsLoading={setIsLoading}
                    form={form}
                    onSubmit={onSubmitForm}
                    productID={props.data.id}
                    price={props.data.price}
                />
            </div>

            <div className="button-container">
                <Button
                    type="primary"
                    className="confirm-button"
                    onClick={() => form.submit()}
                    disabled={isLoading}
                >
                    ตรวจสอบหลักฐานการโอนเงิน
                </Button>
            </div>

            {visible ? (
                <ModalRedirect visible={visible} typeModal={typeModal} />
            ) : null}
        </>
    )
}
export default PaymentPage
