import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { ColorsConstant } from '../../../../assets/colors/Colors'

interface WaitingIconProps {
    fontSize?: number
    background?: string
}

const WaitingIcon: React.FC<WaitingIconProps> = ({
    fontSize = 16,
    background = ColorsConstant.primary,
}) => {
    return (
        <div
            style={{
                width: `${fontSize}px`,
                height: `${fontSize}px`,
                background: `${background}`,
                display: 'grid',
                placeItems: 'center',
                borderRadius: '100%',
            }}
        >
            <LoadingOutlined
                style={{
                    fontSize: `${fontSize / 2}px`,
                    color: 'white',
                    strokeWidth: '80',
                    stroke: 'white',
                }}
            />
        </div>
    )
}

export default WaitingIcon
