import { BaseList } from '../../base'

export interface Slip {
    id: string
    url: string
}
export interface slipOKRes {
    message: string
    success: string
}
export enum Status {
    NO_STATUS = 'NO_STATUS',
    FAILED = 'FAILED',
    WAITING_VERIFY = 'WAITING_VERIFY',
    VERIFYING = 'VERIFYING',
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
}

export interface HistoryData {
    id: string
    status: Status
    selected: boolean
    downloaded: boolean
    fileArr: string[]
    slip: Slip
    slipOKRes: slipOKRes
    uploadSlipDate: string | Date
    verifyDate: string | Date
    userId: string
    productId: string
    productName: string
    price: number
    banner: string
}

export interface HistoryDataRes extends BaseList {
    data: HistoryData[]
}
