import React from 'react'
import { Layout } from 'antd'
import './index.scss'

interface PageLayoutProps {
    children: React.ReactNode
}
const { Content } = Layout

export const PageLayout = ({
    children,
}: PageLayoutProps): React.ReactElement => {
    return (
        <Content className="page-content">
            <div
                className="site-layout-background"
                style={{
                    maxWidth: '1600px',
                    width: '100%',
                }}
            >
                {children}
            </div>
        </Content>
    )
}
