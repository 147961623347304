import liff from '@line/liff'
import { Result, Input, Button, Form } from 'antd'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from '../../contexts/AuthContext'
import { LoginWithLineRequest } from '../../model/interface/response/auth'
import { User } from '../../model/interface/response/customer'
import { AuthService } from '../../services/auth.service'
import { useNotification } from '../../components/app.toast'
import LoadingBox from '../../components/loading'

interface Profile {
    displayName: string
    userId: string
    statusMessage?: string
    pictureUrl?: string
}

const ContainerInput = styled(Form)`
    width: 50%;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const LinePage: React.FC = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { setUser, setIsLoggedIn } = useAuth()
    const { openNotificationWithIcon } = useNotification()
    const [profile, setProfile] = React.useState<Profile>({
        displayName: '',
        userId: '',
    })
    const [email, setEmail] = React.useState('')
    const [isLoading, setisLoading] = React.useState(false)

    useEffect(() => {
        liff.init({
            liffId: process.env.REACT_APP_LINE_LIFF_ID || '',
        }).then(() => {
            handleLogin()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLogin = async () => {
        try {
            const resProfile = await liff.getProfile()
            setProfile(resProfile)
            const resDecodedIDToken = liff.getDecodedIDToken()
            setEmail(resDecodedIDToken?.email || '')
            form.setFieldsValue({
                displayName: resProfile?.displayName,
                email: resDecodedIDToken?.email,
            })
            setisLoading(true)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSetUserWithLine = async () => {
        try {
            const body: LoginWithLineRequest = {
                displayName: profile?.displayName,
                email: email,
                lineId: profile?.userId,
            }
            const resLoginWithLine = await AuthService.loginWithLine(body)
            const decodedToken: User = jwtDecode(resLoginWithLine.token)
            localStorage.setItem('access_token', resLoginWithLine.token)
            setUser(decodedToken)
            setIsLoggedIn(true)
            navigate('/')
        } catch (error) {
            console.log('🚀 ~ error:', error)
            openNotificationWithIcon('error', 'Create account error.')
        }
    }

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {!isLoading ? (
                <LoadingBox />
            ) : (
                <Result
                    style={{ width: '100%' }}
                    status="success"
                    title={`ยินดีต้อนรับ ${profile?.displayName} สู่ Coquet 🎉`}
                    subTitle="ลงทะเบียนผู้ใช้สำเร็จ"
                    extra={[
                        <div
                            key="form"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <ContainerInput
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    displayName: profile?.displayName,
                                    email: email,
                                }}
                            >
                                <Form.Item
                                    name={['displayName']}
                                    label="Display name"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name={['email']} label="อีเมล">
                                    <Input disabled />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    key="navigate-home"
                                    style={{ width: '100%' }}
                                    onClick={handleSetUserWithLine}
                                >
                                    ไปที่หน้าเว็บไซต์
                                </Button>
                            </ContainerInput>
                        </div>,
                    ]}
                />
            )}
        </div>
    )
}

export default LinePage
