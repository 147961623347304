/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Button, Form, Input, Layout, Modal, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { validateMessages } from '../../model/enum/message'
import { CreateAccountRequest } from '../../model/interface/response/customer'
import '../create_account/createAccountPage.scss'

const CreateAccountPage: React.FC = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const { createAccount } = useAuth()

    const validatePassword = (rule: unknown, value: string) => {
        if (value?.length === 0 || value === undefined) {
            return Promise.reject(validateMessages.required_pw)
        }
        if (value && value.length < 8) {
            return Promise.reject(validateMessages.password.count_char)
        }
        if (!(/[a-zA-Z]/.test(value) && /\d/.test(value))) {
            return Promise.reject(validateMessages.password.format_char)
        }
        return Promise.resolve()
    }

    const onFinish = async (values: CreateAccountRequest) => {
        try {
            Modal.confirm({
                title: 'ยืนยันการสร้างบัญชีผู้ใช้',
                content:
                    'ข้อมูลถูกต้องหรือไม่? คุณแน่ใจหรือไม่ที่จะสร้างบัญชีผู้ใช้',
                okText: 'สร้างบัญชีผู้ใช้',
                okType: 'default',
                cancelText: 'ยกเลิก',
                onOk: async () => await createAccount(values),
                centered: true,
            })
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo)
        }
    }

    return (
        <Layout.Content className="card_create">
            <div className="head_content" onClick={() => navigate(-1)}>
                <LeftOutlined />
                <Typography.Paragraph style={{ margin: 0 }}>
                    ย้อนกลับ
                </Typography.Paragraph>
            </div>
            <div className="head_textcard">
                <Typography.Title level={2}>สร้างบัญชีผู้ใช้</Typography.Title>
                <div>มีบัญชีผู้ใช้แล้วหรือไม่? เข้าสู่ระบบที่นี่</div>
            </div>

            <Form
                form={form}
                onFinish={onFinish}
                requiredMark={false}
                layout={'vertical'}
            >
                <Form.Item
                    name={['displayName']}
                    // label="ชื่อที่ใช้แสดงในเว็บไซต์"
                    label={
                        <div>
                            ชื่อผู้ใช้{' '}
                            <Tooltip title="ชื่อที่ใช้แสดงในเว็บไซต์">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    }
                    rules={[
                        {
                            required: true,
                            message: validateMessages.required_name,
                        },
                        {
                            pattern: /^[a-zA-Z0-9_ ]{1,}$/,
                            message: validateMessages.display_name,
                        },
                    ]}
                >
                    <Input placeholder="กรอกชื่อที่ใช้แสดงในเว็บไซต์" />
                </Form.Item>

                <Form.Item
                    name={['email']}
                    label={
                        <div>
                            อีเมล{' '}
                            <Tooltip title="กรุณากรอกอีเมลเป็นตัวพิมพ์เล็ก">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    }
                    rules={[
                        {
                            required: true,
                            message: validateMessages.required_mail,
                        },
                        {
                            pattern: /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: validateMessages.types.email,
                        },
                    ]}
                >
                    <Input placeholder="ตัวอย่าง example@email.com" />
                </Form.Item>

                <Form.Item
                    name={['password']}
                    label="รหัสผ่าน"
                    rules={[{ validator: validatePassword }]}
                >
                    <Input.Password placeholder="กรุณากรอกรหัสผ่าน" />
                </Form.Item>

                <Form.Item
                    name={['confirm']}
                    label="ยืนยันรหัสผ่าน"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'กรุณายืนยันรหัสผ่าน!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(
                                    new Error('กรุณายืนยันรหัสผ่านให้ถูกต้อง!')
                                )
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="กรุณายืนยันรหัสผ่าน" />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                    >
                        สร้างบัญชีผู้ใช้
                    </Button>
                </Form.Item>
            </Form>
        </Layout.Content>
    )
}

export default CreateAccountPage
