import React from 'react'
import { useNavigate } from 'react-router-dom'
import './index.scss'

interface ProductContentProps {
    id: string
    banner: string
    title: string
    description: string
    current: string
}

const ProductContent = (props: ProductContentProps): JSX.Element => {
    const navigate = useNavigate()
    const { banner, title, description, id, current } = props
    const handleClick = (id: string) => {
        navigate(current === 'blogs' ? `/blogs/${id}` : `/products/${id}`)
    }
    return (
        <div
            style={{ width: '100%', marginBottom: '20px', cursor: 'pointer' }}
            onClick={() => handleClick(id)}
        >
            <img
                alt={id}
                src={banner}
                style={{
                    width: '100%',
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                }}
            />
            <h2 className="title">{title}</h2>
            <div
                className="description"
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            ></div>
        </div>
    )
}

export default ProductContent
