import {
    IBlogResponse,
    BlogRecommendApiResponse,
    RecentBlogResponse,
    BlogData,
} from '../model/interface/response/blog'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class BlogService {
    private static url = `${prefixApi.blog}`

    public static async getAllBlogs(
        page?: number,
        pageSize?: number
    ): Promise<IBlogResponse> {
        const queryParams = new URLSearchParams()
        if (page) queryParams.append('page', page.toString())
        if (pageSize) queryParams.append('pageSize', pageSize.toString())
        const url = `${this.url}s${queryParams.toString() ? `?${queryParams.toString()}` : ''}`
        const res = await axiosInstance.get<IBlogResponse>(url)
        return res.data
    }

    public static async getBlogById(id?: string): Promise<BlogData> {
        const res = await axiosInstance.get<BlogData>(`${this.url}/${id}`)
        return res.data
    }

    public static async getBlogRecommendation(): Promise<BlogRecommendApiResponse> {
        const res = await axiosInstance.get<BlogRecommendApiResponse>(
            `${this.url}/recommendations`
        )
        return res.data
    }

    public static async getRecentBlog(): Promise<RecentBlogResponse> {
        const res = await axiosInstance.get<RecentBlogResponse>(
            `${this.url}/recent`
        )
        return res.data
    }
}
