import React from 'react'
import { Col, Pagination, Row } from 'antd'
import BlogAndProductContent from './components/blogProductContent'
import { IBlogResponse } from '../../../../model/interface/response/blog'
import LoadingBox from '../../../../components/loading'

interface BlogProductLayoutProps {
    data: IBlogResponse
    onPaginationChange: (current: number, pagination: number) => void
    current: string
    loading: boolean
}

const BlogLayout = (props: BlogProductLayoutProps): JSX.Element => {
    const { data, onPaginationChange, current, loading } = props
    return (
        <>
            {!loading ? (
                <Row gutter={[56, 24]}>
                    {data.data.map((element) => (
                        <Col
                            key={element.id}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            span={24}
                        >
                            <BlogAndProductContent
                                id={element.id}
                                image={element.image}
                                title={element.title}
                                shortDesc={element.shortDesc}
                                current={current}
                                content={[]}
                                updateDate={''}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <LoadingBox />
            )}
            <Row
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                }}
            >
                <Pagination
                    current={data.page}
                    total={data.total}
                    showSizeChanger
                    onChange={onPaginationChange}
                />
            </Row>
        </>
    )
}

export default BlogLayout
