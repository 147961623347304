import React from 'react'
import { Button, Card, Image, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ProductData } from '../../../model/interface/response/products'
import { LeftOutlined } from '@ant-design/icons'
import Title from 'antd/es/typography/Title'
import { useAuth } from '../../../contexts/AuthContext'
import ModalLogin from '../../../components/app.login.modal'

interface OrderProps {
    current: number
    onNext: () => void
    onPrev: () => void
    data: ProductData | undefined
    numberState: number
}

const OrderPage = (props: OrderProps): JSX.Element => {
    const navigate = useNavigate()
    const { user } = useAuth()

    const payment = () => {
        props.onNext()
    }

    return (
        <>
            <div className="head_content" onClick={() => navigate(-1)}>
                <LeftOutlined />
                <Typography.Paragraph style={{ margin: 0 }}>
                    ย้อนกลับ
                </Typography.Paragraph>
            </div>

            <Image
                src={props.data?.orderBanner}
                alt=""
                style={{
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                    maxWidth: '1080px',
                }}
            />
            <Title level={3} style={{ margin: 0 }}>
                รายละเอียดคำสั่งซื้อ
            </Title>
            <div
                className="text-description"
                dangerouslySetInnerHTML={{
                    __html: props.data?.orderDetail || '',
                }}
            />
            <Card
                title="สรุปค่าใช้จ่าย"
                size={'small'}
                bordered={false}
                className="summaryCard"
            >
                <div className="summaryContent">
                    <p>ราคารวม</p>
                    <p>{props.data?.price.toLocaleString()} บาท</p>
                </div>
            </Card>

            <div className="button-container">
                {props.current < props.numberState - 1 && (
                    <>
                        {user ? (
                            <Button
                                type="primary"
                                onClick={payment}
                                className="confirm-button"
                            >
                                ยืนยันสั่งซื้อ
                            </Button>
                        ) : (
                            <Button type="primary" className="confirm-button">
                                <ModalLogin
                                    modal_label="เข้าสู่ระบบเพื่อสั่งซื้อ"
                                    where_modal="navbar"
                                />
                            </Button>
                        )}
                    </>
                )}
            </div>
        </>
    )
}
export default OrderPage
