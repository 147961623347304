import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

interface MenuIconProps {
    openMenu: boolean
    setOpenMenu: Dispatch<SetStateAction<boolean>>
}

const IconContainer = styled.div`
    display: none;
    .bar1,
    .bar2,
    .bar3 {
        width: 35px;
        height: 4px;
        background-color: #333;
        margin: 6px 0;
        transition: 0.2s;
    }
    .change .bar1 {
        transform: translate(0, 10px) rotate(-45deg);
    }
    .change .bar2 {
        opacity: 0;
    }
    .change .bar3 {
        transform: translate(0, -10px) rotate(45deg);
    }
    @media (max-width: 786px) {
        display: inline-block;
        cursor: pointer;
    }
`

const MenuIcon: React.FC<MenuIconProps> = ({ openMenu, setOpenMenu }) => {
    return (
        <IconContainer onClick={() => setOpenMenu((perv) => !perv)}>
            <div className={`${openMenu ? 'change' : ''}`}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        </IconContainer>
    )
}

export default MenuIcon
