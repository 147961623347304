interface PrefixAPI {
    auth: string
    product: string
    masterData: string
    blog: string
    content: string
    banner: string
    file_merge: string
    customer: string
    payment: string
    paid: string
    verify: string
}

export const prefixApi: PrefixAPI = {
    auth: '/api/web/auth',
    product: '/api/web/product',
    masterData: 'api/web/master-data',
    blog: 'api/web/blog',
    content: 'api/web/content',
    banner: 'api/web/banner',
    file_merge: 'api/web/paid',
    customer: 'api/web/customer',
    payment: 'api/web/paid/verify-slip',
    paid: 'api/web/paid',
    verify: 'api/web/verify',
}
