import React, { useEffect, useState } from 'react'
import '../order_detail/orderDetailPage.scss'
import { Steps } from 'antd'
import { useParams } from 'react-router-dom'
import { ProductData } from '../../model/interface/response/products'
import { ProductService } from '../../services/product.service'
import LoadingBox from '../../components/loading'
import OrderPage from './components/order'
import PaymentPage from './components/payment'

const initialProductDetail: ProductData = {
    id: '',
    banner: '',
    bannerMobile: '',
    otherImageUrl: [],
    lineOAOption: false,
    optionImageUrl: '',
    title: '',
    shortDesc: '',
    description: '',
    category: { id: '', name: '' },
    price: 0,
    orderDetail: '',
    orderBanner: '',
    paymentDetail: '',
    productItem: [],
    createDate: '',
    updateDate: '',
    createBy: { email: '', id: '' },
}
const OrderDetailPage = (): JSX.Element => {
    const [current, setCurrent] = useState(0)
    const { id } = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [productDetail, setProductDetail] =
        useState<ProductData>(initialProductDetail)

    const next = () => {
        setCurrent(current + 1)
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    setLoading(true)
                    const productDetailData =
                        await ProductService.getProductNotPaidById(id)
                    setProductDetail(productDetailData)
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    const steps = [
        {
            title: 'รายละเอียดคำสั่งซื้อ',
            content: (
                <OrderPage
                    current={current}
                    onNext={() => next()}
                    onPrev={() => prev()}
                    data={productDetail}
                    numberState={3}
                />
            ),
            description: 'ตรวจสอบคำสั่งซื้อ',
        },
        {
            title: 'การชำระเงิน',
            content: (
                <PaymentPage
                    current={current}
                    onPrev={() => prev()}
                    data={productDetail}
                    numberState={2}
                />
            ),
            description: 'ชำระเงินสินค้า',
        },
    ]
    return (
        <>
            {!loading && productDetail ? (
                <div className="order-detail-container">
                    <div className="content">
                        <Steps current={current} size="small" items={steps} />
                        {steps[current].content}
                    </div>
                </div>
            ) : (
                <LoadingBox />
            )}
        </>
    )
}
export default OrderDetailPage
