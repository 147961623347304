import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../options/optionsPage.scss'
import { useNavigate } from 'react-router-dom'
import { IMasterDataItem } from '../../model/interface/response/masterData'
import { MasterDataService } from '../../services/masterData.service'
import { ProductData } from '../../model/interface/response/products'
import { ProductService } from '../../services/product.service'
import { Spin } from 'antd'
import { PageLayout } from '../../components/page-layout'

const OptionsPage = (): JSX.Element => {
    // const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const handleProductDetail = () => {
        // ทำการ navigate ไปยังหน้า /home/1
        navigate(`/order/${id}`)
    }
    const [masterData, setMasterData] = useState<IMasterDataItem>()
    const [product, setProduct] = useState<ProductData>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const masterData =
                    await MasterDataService.getMasterDataByRefCode('contact')
                const filteredData = masterData.data.filter(
                    (item) => item.masterDataName === 'LINE Official Account'
                )
                if (id) {
                    const productData =
                        await ProductService.getProductNotPaidById(id)
                    setProduct(productData)
                }
                setMasterData(filteredData[0])
                setLoading(false)
            } catch (error) {
                console.error('Error fetching master data:', error)
            }
        }
        fetchData()
    }, [])

    return (
        <PageLayout>
            <div className="header-container">
                <h2 style={{ textAlign: 'center' }}>เลือกไฟล์ PDF สำหรับคุณ</h2>
            </div>
            <div className="image-products">
                <div className="container" onClick={handleProductDetail}>
                    <Spin spinning={loading}>
                        <img
                            style={{
                                width: '100%',
                                aspectRatio: '16/9',
                                objectFit: 'cover',
                            }}
                            src={product?.optionImageUrl || product?.banner}
                            alt="choose_option"
                        />
                    </Spin>
                    <div className="text-overlay">
                        เลือกไฟล์ PDF ตามแต่ละหัวข้อ เพื่อสร้างไฟล์ PDF
                        สำหรับคุณ
                    </div>
                </div>
                <div className="container">
                    <a href={masterData?.description}>
                        <img
                            style={{
                                width: '100%',
                                aspectRatio: '16/9',
                                objectFit: 'cover',
                            }}
                            src="/images/lineOA.png"
                            alt="lineOA"
                        />
                    </a>
                </div>
            </div>
        </PageLayout>
    )
}

export default OptionsPage
