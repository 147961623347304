/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Col, Flex, Row } from 'antd'
import { Footer } from 'antd/es/layout/layout'
import '../assets/scss/components/_layout.scss'
import { useAuth } from '../contexts/AuthContext'
import { Link, NavLink, useLocation } from 'react-router-dom'
import ModalLogin from './app.login.modal'
import { IMasterDataItem } from '../model/interface/response/masterData'
import { MasterDataService } from '../services/masterData.service'

interface FooterAppProps {
    logo: string
}

const FooterApp: React.FC<FooterAppProps> = ({ logo = '' }) => {
    const [homePath, setHomePath] = useState(false)
    const current_location = useLocation()

    const [footer, setFooter] = useState<IMasterDataItem>()
    const fetchFooter = React.useCallback(async () => {
        try {
            const categoryResponse =
                await MasterDataService.getMasterDataByRefCode('footer')
            setFooter(categoryResponse.data[0])
        } catch (error) {
            console.error('Error fetching footer:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchFooter()
    }, [fetchFooter])

    const [contact, setContact] = useState<IMasterDataItem[]>([])
    const fetchContact = React.useCallback(async () => {
        try {
            const categoryResponse =
                await MasterDataService.getMasterDataByRefCode('contact')
            setContact(categoryResponse.data)
        } catch (error) {
            console.error('Error fetching contact:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchContact()
    }, [fetchContact])

    useEffect(() => {
        if (current_location.pathname === '/') {
            setHomePath(true)
        } else {
            setHomePath(false)
        }
    }, [current_location.pathname, setHomePath])

    const { logout, user } = useAuth()
    const handleLogout = () => {
        logout()
    }
    return (
        <Footer className="card-footer">
            <Link to="/" className="logo-container">
                <img
                    src={logo}
                    alt=""
                    style={{
                        width: '100%',
                        aspectRatio: '16/9',
                        objectFit: 'cover',
                    }}
                />
            </Link>
            <Row justify="center">
                <Col xs={24} md={20}>
                    <div style={{ textAlign: 'center', margin: '8px' }}>
                        {footer?.description}
                    </div>
                </Col>
            </Row>
            {homePath && (
                <Flex className="link-container" wrap="wrap" gap="middle">
                    <NavLink to="/">หน้าแรก</NavLink>
                    <NavLink to="/products">ผลิตภัณท์</NavLink>
                    <NavLink to="/blogs">บทความ</NavLink>
                    {user ? (
                        <a onClick={handleLogout}>ออกจากระบบ</a>
                    ) : (
                        <ModalLogin
                            where_modal="footer"
                            modal_label="เข้าสู่ระบบ"
                        />
                    )}
                </Flex>
            )}
            {homePath && (
                <Flex className="link-container" wrap="wrap" gap="middle">
                    {contact.map((item) => (
                        <a
                            style={{ wordBreak: 'break-word' }}
                            key={item.id}
                            href={`${item.description}`}
                        >
                            {item.masterDataName}
                        </a>
                    ))}
                </Flex>
            )}
            <hr />
            <div>© All right reserved. Coquet Beauty 2024</div>
        </Footer>
    )
}

export default FooterApp
