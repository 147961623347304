/* eslint-disable @typescript-eslint/no-unused-vars */
import { Image } from 'antd'
import React from 'react'

interface BlogContentsProps {
    type: 'Description' | 'ImageURL'
    description: string
}

const BlogContents: React.FC<BlogContentsProps> = ({ type, description }) => {
    if (type === 'Description') {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            ></div>
        )
    } else if (type === 'ImageURL') {
        return (
            <Image
                width={'100%'}
                style={{
                    aspectRatio: '16/9',
                    objectFit: 'cover',
                }}
                src={description}
                alt=""
            />
        )
    }
    return null
}

export default BlogContents
