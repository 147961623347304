import React, { useState } from 'react'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import '../assets/scss/components/_layout.scss'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const MenuProfile = styled(Menu)`
    .ant-menu-submenu div.ant-menu-submenu-title span.ant-menu-title-content {
        margin: 0;
    }
`

const items: MenuProps['items'] = [
    {
        key: 'SubMenu',
        icon: <UserOutlined />,
        children: [
            {
                label: 'โปรไฟล์',
                key: 'profile',
            },
            {
                label: 'ประวัติการซื้อ',
                key: 'history',
            },
            {
                label: 'ออกจากระบบ',
                key: 'logout',
            },
        ],
    },
]
export const IconUser: React.FC = () => {
    const [current, setCurrent] = useState('')
    const { logout } = useAuth()
    const navigate = useNavigate()

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'logout') {
            logout()
        } else if (e.key === 'profile') {
            navigate(`/profile`)
        } else if (e.key === 'history') {
            navigate(`/history`)
        } else {
            setCurrent(e.key)
        }
    }

    return (
        <MenuProfile
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
        />
    )
}
