/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Col, Pagination, Row } from 'antd'
import { ProductResponse } from '../../../../model/interface/response/products'
import ProductContent from './components/blogProductContent'
import LoadingBox from '../../../../components/loading'

interface ProductLayoutProps {
    data: ProductResponse
    onPaginationChange: (current: number, pagination: number) => void
    current: string
    loading: boolean
}

const ProductLayout = (props: ProductLayoutProps): JSX.Element => {
    const { data, onPaginationChange, current, loading } = props
    return (
        <>
            {!loading ? (
                <Row gutter={[56, 24]}>
                    {data.data.map((element) => (
                        <Col
                            key={element.id || element.title}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            span={24}
                        >
                            <ProductContent
                                id={element.id}
                                banner={element.banner}
                                title={element.title}
                                description={element.shortDesc}
                                current={current}
                            />
                        </Col>
                    ))}
                </Row>
            ) : (
                <LoadingBox />
            )}
            <Row
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                }}
            >
                <Pagination
                    current={data.page}
                    total={data.total}
                    showSizeChanger
                    onChange={onPaginationChange}
                />
            </Row>
        </>
    )
}

export default ProductLayout
