import React, { useEffect, useState } from 'react'
import { Empty, PaginationProps } from 'antd'
import { PageLayout } from '../../components/page-layout'
import BlogProductLayout from './components/blog-layout'
import { BlogService } from '../../services/blog.service'
import { IBlogResponse } from '../../model/interface/response/blog'
import Title from 'antd/es/typography/Title'

const BlogsPage = (): JSX.Element => {
    const [blogData, setBlogData] = useState<IBlogResponse>()
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState<{
        page: number
        pageSize: number
    }>({ page: 1, pageSize: 10 })

    const { page, pageSize } = pagination

    const onPaginationChange: PaginationProps['onChange'] = async (
        current,
        pageSize
    ) => {
        setPagination({ page: current, pageSize })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const blogsData = await BlogService.getAllBlogs(page, pageSize)
                setBlogData(blogsData)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
    }, [page, pageSize])

    return (
        <PageLayout>
            <Title level={3} style={{ marginBottom: '40px' }}>
                บทความทั้งหมด
            </Title>
            {blogData?.data.length !== 0 ? (
                <BlogProductLayout
                    data={
                        blogData || {
                            data: [],
                            total: 0,
                            page: 1,
                            pageSize: 10,
                        }
                    }
                    onPaginationChange={onPaginationChange}
                    current="blogs"
                    loading={loading}
                />
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="ไม่มีข้อมูล"
                />
            )}
        </PageLayout>
    )
}

export default BlogsPage
