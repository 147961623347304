import React, { useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Upload, message, UploadFile, UploadProps } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'

const { Dragger } = Upload

export const UploadImg = ({
    onUploadImgSuccess,
    productID,
    onLoadingChange
}: {
    onUploadImgSuccess: (url: string, productID: string) => void
    onUploadImgDelete?: () => void
    productID: string
    onLoadingChange?: (isLoading: boolean) => void
}): JSX.Element => {
    const [imageUrl, setImageUrl] = useState('')
    const [previewImageContent, setPreviewImageContent] = useState<
        UploadFile[]
    >([])
    const urlParams = new URLSearchParams(location.search)
    const refIdParams = urlParams.get('refId')

    const [refId, setRefId] = React.useState(refIdParams || '')
    const customRequest = async (options: UploadRequestOption) => {
        const { file, onSuccess, onError } = options
        const formData = new FormData()
        formData.append('slip', file)
        formData.append('productId', productID)

        const xhr = new XMLHttpRequest()
        xhr.open(
            'POST',
            `${process.env.REACT_APP_API_URL}/api/web/upload-slip${refId !== '' ? `?refId=${refId}` : ''}`,
            true
        )
        xhr.setRequestHeader(
            'Authorization',
            `Bearer ${window.localStorage.getItem('access_token')}`
        )
        xhr.setRequestHeader('accept', 'application/json')

        xhr.onload = () => {
            if (onLoadingChange) {
                onLoadingChange(false)
            }
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText)
                console.log('🚀 ~ customRequest ~ response:', response)
                const { url, id } = response
                message.success(`${(file as File).name} อัปโหลดไฟล์เสร็จสิ้น`)
                if (onSuccess) {
                    setRefId(id)
                    onUploadImgSuccess(url, id)
                    onSuccess(response)
                }
            } else {
                message.error(`${(file as File).name} อัปโหลดไฟล์ล้มเหลว`)
                if (onError) {
                    onError(new Error('อัปโหลดไฟล์ล้มเหลว'))
                }
            }
        }

        xhr.onerror = () => {
            if (onLoadingChange) {
                onLoadingChange(false)
            }
            message.error(`${(file as File).name} อัปโหลดไฟล์ล้มเหลว`)
            if (onError) {
                onError(new Error('อัปโหลดไฟล์ล้มเหลว'))
            }
        }

        if (onLoadingChange) {
            onLoadingChange(true)
        }
        xhr.send(formData)
    }

    const uploadProps: UploadProps = {
        name: 'slip',
        multiple: false,
        accept: 'image/jpg, image/png, image/jpeg',
        maxCount: 1,
        customRequest,
        fileList: previewImageContent,
        onChange(info) {
            const { status, response } = info?.file
            if (status === 'done') {
                const { url } = response
                setImageUrl(url)
            } else if (status === 'error') {
                const { url } = response
                setImageUrl(url)
            }
            setPreviewImageContent(info.fileList)
        },
        onPreview: async (file: UploadFile) => {
            window.open(file.url || file.response.url, '_blank')
        },
    }

    return (
        <Dragger {...uploadProps} style={{ width: '100%', height: '200px' }}>
            {previewImageContent.length === 0 && (
                <>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">เลือกไฟล์หรือลากมาที่นี่</p>
                    <p className="ant-upload-hint">รองรับ PNG, JPG, JPEG</p>
                </>
            )}
            {previewImageContent.length > 0 && (
                <img
                    src={imageUrl}
                    alt="Preview"
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '200px',
                    }}
                />
            )}
        </Dragger>
    )
}
