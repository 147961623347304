import React from 'react'
import { Col, Row } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { useNotification } from '../../../components/app.toast'

interface CardAccountProps {
    accountName: string
    accountNo: string
    bankName: string
    bankImage: string
    bankBranch: string
}

const CardAccount = (props: CardAccountProps): JSX.Element => {
    const { openNotificationWithIcon, contextHolder } = useNotification()

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(
                props.accountNo.replace(/-/g, '')
            )
            openNotificationWithIcon('success', 'success_copy')
        } catch (err) {
            openNotificationWithIcon('error', 'error_copy')
        }
    }
    return (
        <>
            <Row className="cardAccount">
                <Row className="leftCard">
                    <img
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                        src={`${props.bankImage}`}
                        alt="bankImage"
                    />
                </Row>
                <Row className="rightCard">
                    <Col>
                        <h4 style={{ margin: '2px' }}>
                            ชื่อบัญชี {props.accountName}
                        </h4>
                        <h4 style={{ margin: '2px' }}>{props.bankName}</h4>
                        <p style={{ margin: '2px' }}>{props.bankBranch}</p>
                    </Col>

                    <Row
                        onClick={handleCopyClick}
                        className='clipboard'
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            gap: '12px',
                        }}
                    >
                        <h3>{props.accountNo}</h3>
                        <CopyOutlined />
                    </Row>
                </Row>
            </Row>
            {contextHolder}
        </>
    )
}
export default CardAccount
