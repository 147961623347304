import {
    CreateAccountRequest,
    CreateAccountResponse,
    EditAccountRequest,
    EditAccountResponse,
} from '../model/interface/response/customer'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class CustomerService {
    private static url = `${prefixApi.customer}`

    public static async createAccount(
        data: CreateAccountRequest
    ): Promise<CreateAccountResponse> {
        const res = await axiosInstance.post<CreateAccountResponse>(
            `${this.url}`,
            data
        )
        return res.data
    }
    public static async editAccount(
        data: EditAccountRequest,
        id: string
    ): Promise<EditAccountResponse> {
        const res = await axiosInstance.put<EditAccountResponse>(
            `${this.url}/${id}`,
            data
        )
        return res.data
    }
}
