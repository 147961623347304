/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import { Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

const { Title } = Typography

interface ModalProps {
    visible: boolean
    typeModal: string
}

const ModalRedirect: React.FC<ModalProps> = ({ visible, typeModal }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(visible)
    }, [])

    const handleCancel = () => {
        navigate('/history')
    }

    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            closeIcon={false}
            footer={[
                <Button key="back" type="primary" onClick={handleCancel}>
                    ยกเลิกการตรวจสอบ
                </Button>,
            ]}
            className="modal_card"
            centered
            maskClosable={false}
        >
            <Title style={{ color: 'gray' }}>กรุณารอสักครู่</Title>

            <Spin size="large" />

            <span>ระบบกำลังตรวจสอบรายการการชำระเงินของท่าน</span>
        </Modal>
    )
}

export default ModalRedirect
