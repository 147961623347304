import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'
import { IMasterDataItemResponse } from '../model/interface/response/masterData'
import { REF_CODE } from '../model/interface/masterData'

export class MasterDataService {
    private static masterDataUrl = `${prefixApi.masterData}`

    public static async getMasterDataByRefCode(
        refCode: REF_CODE
    ): Promise<IMasterDataItemResponse> {
        const res = await axiosInstance.get<IMasterDataItemResponse>(
            `${this.masterDataUrl}/code/${refCode}`
        )
        return res.data
    }
}
